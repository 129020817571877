import React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { ErrorCorrectionLevels } from '../../enums/errorCorrectionLevels';

interface QRCodeProps {
  value: string;
  size?: number;
  level?: ErrorCorrectionLevels;
  bgColor?: string;
  fgColor?: string;
  includeMargin?: boolean;
}

const QRCodeComponent: React.FC<QRCodeProps> = ({
  value,
  size = 128,
  level = ErrorCorrectionLevels.L,
  bgColor = '#ffffff',
  fgColor = '#000000',
  includeMargin = false
}) => {
  return (
    <QRCodeSVG
      value={value}
      size={size}
      level={level}
      bgColor={bgColor}
      fgColor={fgColor}
      includeMargin={includeMargin}
    />
  );
};

export default QRCodeComponent;