// src/utils/mappers.ts
import {ReceiptV0, ReceiptV1} from '../model/receipt';
import {Transaction} from '../model/transaction';
import {ReceiptMapper} from "../model/ReceiptMapper";

const generateEAN13 = (receiptNumber: string = ''): string => {
    let cleanNumber = receiptNumber.replace(/\D/g, '');
    while (cleanNumber.length < 12) {
        cleanNumber = '0' + cleanNumber;
    }
    if (cleanNumber.length > 12) {
        cleanNumber = cleanNumber.slice(-12);
    }
    let sum = 0;
    for (let i = 0; i < 12; i++) {
        const digit = parseInt(cleanNumber[i]);
        sum += (i % 2 === 0) ? digit : digit * 3;
    }
    const checkDigit = (10 - (sum % 10)) % 10;
    return cleanNumber + checkDigit;
};

const isV0Receipt = (receipt: any): receipt is ReceiptV0 => {
    return receipt?.generalInformation?.type !== undefined;
};

const isV1Receipt = (receipt: any): receipt is ReceiptV1 => {
    return receipt?.xReceipts !== undefined;
};

export const mapReceiptToTransaction = (receipt: any): Transaction => {
    if (!receipt) {
        throw new Error('Receipt is undefined');
    }

    try {
        // Convert receipt to V1 format if it's V0
        const v1Receipt: ReceiptV1 = isV0Receipt(receipt)
            ? ReceiptMapper.v0ToV1(receipt)
            : receipt;

        // Generate valid EAN13
        const validEAN13 = generateEAN13(
            v1Receipt.generalInformation.receiptNumber
        );

        // Map to transaction
        return {
            id: validEAN13,
            date: v1Receipt.generalInformation.systemTimestamp,
            amount: v1Receipt.orderSummary.totalAmountIncVat,
            description: v1Receipt.merchant.merchantName,
            currency: v1Receipt.orderSummary.currencyIsoCode,
            merchant: {
                name: v1Receipt.merchant.merchantName,
                location: v1Receipt.merchant.branch.address
                    ? `${v1Receipt.merchant.branch.address.city}, ${v1Receipt.merchant.branch.address.addressLine1}`
                    : 'Unknown Location'
            },
            receipt: v1Receipt
        };

    } catch (error) {
        console.error('Error mapping receipt to transaction:', error);

        // Return a safe default transaction
        return {
            id: generateEAN13(Date.now().toString()),
            date: new Date().toISOString(),
            amount: 0,
            description: 'Error Processing Receipt',
            currency: 'NOK',
            merchant: {
                name: 'Unknown Merchant',
                location: 'Unknown Location'
            },
            receipt: receipt
        };
    }
};

// Helper for safer receipt access
export class ReceiptAccessor {
    private receipt: ReceiptV1;

    constructor(receipt: ReceiptV0 | ReceiptV1) {
        this.receipt = isV0Receipt(receipt)
            ? ReceiptMapper.v0ToV1(receipt)
            : receipt;
    }

    getMerchantName(): string {
        return this.receipt.merchant.merchantName || 'Unknown Merchant';
    }

    getAmount(): number {
        return this.receipt.orderSummary.totalAmountIncVat || 0;
    }

    getCurrency(): string {
        return this.receipt.orderSummary.currencyIsoCode || 'NOK';
    }

    getTimestamp(): string {
        return this.receipt.generalInformation.systemTimestamp || new Date().toISOString();
    }

    getLocation(): string {
        const address = this.receipt.merchant.branch.address;
        if (!address) return 'Unknown Location';
        return `${address.city || ''}, ${address.addressLine1 || ''}`.trim() || 'Unknown Location';
    }

    getReceiptNumber(): string {
        return this.receipt.generalInformation.receiptNumber || Date.now().toString();
    }
}

// Updated mapper using ReceiptAccessor
export const mapReceiptToTransactionSafe = (receipt: ReceiptV0 | ReceiptV1): Transaction => {
    if (!receipt) {
        throw new Error('Receipt is undefined');
    }

    try {
        const accessor = new ReceiptAccessor(receipt);

        return <Transaction>{
            id: generateEAN13(accessor.getReceiptNumber()),
            date: accessor.getTimestamp(),
            amount: accessor.getAmount(),
            description: accessor.getMerchantName(),
            currency: accessor.getCurrency(),
            merchant: {
                name: accessor.getMerchantName(),
                location: accessor.getLocation()
            },
            receipt: receipt
        };
    } catch (error) {
        console.error('Error mapping receipt to transaction:', error);
        return <Transaction>{
            id: generateEAN13(Date.now().toString()),
            date: new Date().toISOString(),
            amount: 0,
            description: 'Error Processing Receipt',
            currency: 'NOK',
            merchant: {
                name: 'Unknown Merchant',
                location: 'Unknown Location'
            },
            receipt: receipt
        };
    }
};