import React from 'react';

interface LineItem {
  itemName: string;
  itemQuantity: {
    quantity: number;
  };
  itemSumTotal: number;
  itemIds?: {
    id?: string;
  };
  itemDiscount?: {
    percentage: number;
    amount: number;
  };
  itemMetaData?: {
    reward?: {
      valueType: string;
      valueCurrency: string;
      valueAmount: number;
    };
    co2?: {
      co2Kg: number;
    };
  };
}

interface LineItemCardProps {
  item: LineItem;
  currencyIsoCode?: string;
  isLastItem?: boolean;
}

export const LineItemCard = ({
                        item,
                        currencyIsoCode = 'NOK',
                        isLastItem = false
                      }: LineItemCardProps) => {
  // Helper function for safe number formatting
  const formatNumber = (num: number | undefined): string => {
    try {
      return (num || 0).toFixed(2);
    } catch {
      return '0.00';
    }
  };

  // Helper for safe property access
  const safeGet = <T extends unknown>(obj: any, path: string, defaultValue: T): T => {
    try {
      const value = path.split('.').reduce((o, key) => o?.[key], obj);
      return value !== undefined ? value : defaultValue;
    } catch {
      return defaultValue;
    }
  };

  // Extract values safely
  const itemName = safeGet(item, 'itemName', 'Unknown Item');
  const quantity = safeGet(item, 'itemQuantity.quantity', 1);
  const total = safeGet(item, 'itemSumTotal', 0);
  const itemId = safeGet(item, 'itemIds.id', '');
  const discount = {
    percentage: safeGet(item, 'itemDiscount.percentage', 0),
    amount: safeGet(item, 'itemDiscount.amount', 0)
  };
  const reward = {
    valueType: safeGet(item, 'itemMetaData.reward.valueType', ''),
    valueCurrency: safeGet(item, 'itemMetaData.reward.valueCurrency', ''),
    valueAmount: safeGet(item, 'itemMetaData.reward.valueAmount', 0)
  };
  const co2Kg = safeGet(item, 'itemMetaData.co2.co2Kg', null);

  return (
      <div className="line-item-wrapper">
        {/* Main product info row */}
        <div className="content-row">
          <div className="left-content">
            <span className="poppins item-title">{itemName}</span>
            {itemId && <span className="item-id">{itemId}</span>}
          </div>
          <div className="poppins center-content">
            <span>{quantity}</span>
          </div>
          <div className="poppins right-content">
            <span>{formatNumber(total)} {currencyIsoCode}</span>
          </div>
        </div>

        {/* Discount row */}
        {discount.amount > 0 && (
            <div className="content-row">
              <div className="left-content">
                <span className="dot" />
                <span className="poppins info-text">Discount</span>
              </div>
              <div className="poppins center-content">
                <span>{discount.percentage}%</span>
              </div>
              <div className="poppins right-content">
                <span>- {formatNumber(discount.amount)} {currencyIsoCode}</span>
              </div>
            </div>
        )}

        {/* Reward points row */}
        {reward.valueAmount > 0 && (
            <div className="content-row">
              <div className="left-content">
                <span className="dot" />
                <span className="poppins info-text">Reward Points</span>
              </div>
              <div className="poppins center-content">
                <span>{reward.valueAmount}</span>
              </div>
              <div className="poppins right-content">
                <span>{reward.valueCurrency} {formatNumber(reward.valueAmount)}</span>
              </div>
            </div>
        )}

        {/* CO2 row */}
        {co2Kg !== null && (
            <div className="content-row">
              <div className="left-content">
                <span className="dot" />
                <span className="poppins info-text">CO2 Emission</span>
              </div>
              <div className="poppins center-content">
                <span>{co2Kg}</span>
              </div>
              <div className="poppins right-content">
                <span>{co2Kg} kg</span>
              </div>
            </div>
        )}
      </div>
  );
};

        export default LineItemCard;