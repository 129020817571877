// src/dummyData/transactions.ts

import { Transaction } from '../model/transaction';
import { ReceiptV1 } from '../model/receipt';
// import { ErrorCorrectionLevels } from '../enums/errorCorrectionLevels';

const dummyReceipt: ReceiptV1 = {
  id: "0",
  xReceipts: {
    cardholderReference: "550e8400-e29b-41d4-a716-44665544IUYT",
    roundTripId: "550e8400-e29b-41d4-a716-446655440000",
    cardholderMemberships: [
      {
        memberValue: "0134259",
        memberValueType: "MEMBER_NUMBER",
        memberValueProvider: "OBOS"
      }
    ]
  },
  header: {
    headerText: "Mintage Sushi & Asian Dining",
    merchantLogoUrl: "https://cdn.prod.website-files.com/6280c8c695730e6a99ffd331/6280c8e8b2137d6a76baffb0_logo%20png%20svart.PNG"
  },
  generalInformation: {
    receiptType: "ELECTRONIC SALES RECEIPT",
    systemTimestamp: "2024-09-21T12:30:00+01:00",
    receiptNumber: "4006381333931",
    receiptNumberToBarcode: {
      format: "EAN",
      applicationId: "AI"
    },
  },
  merchant: {
    merchantName: "Mintage Sushi Steinkjer",
    merchantCompanyId: "9101698758",
    branch: {
      branchName: "Mintage Sushi Steinkjer",
      slogan: "Fresh sushi, great ambiance",
      email: "downtown@mintagesushi.com",
      phone: "+4740406060",
      websiteUrl: "https://www.mintagesushi.com",
      address: {
        addressLine1: "48 Main Street",
        addressLine2: "Floor 1",
        city: "Oslo",
        zipCode: "0123",
        country: "NOR"
      }
    }
  },
  lineItems: [
    {
      itemName: "California Roll",
      itemDescription: "8 pcs - makiroll",
      itemIds: {
        id: "M0258",
      },
      itemPrice: {
        priceIncVat: 12.99,
        priceExcVat: 11.29,
        vatRate: 0.15,
        vatAmount: 1.70
      },
      itemQuantity: {
        type: "SINGLE",
        quantity: 2
      },
      itemDiscount: {
        amount: 2,
        percentage: 1,
        description: "Recurring Customer",
      },
      itemSumTotal: 25.98,
      itemMetaData: {
        reward: {
          valueType: "OBOS",
          valueCurrency: "Points",
          valueAmount: 20
        },
        co2: {
          co2Kg: 0.5
        }
      }
    },
    {
      itemName: "Green Tea",
      itemDescription: "Hot green tea",
      itemIds: {
        id: "D005",
        ean: "87654321",
        gtin: "87654321",
        epd: "EPD-002"
      },
      itemPrice: {
        priceIncVat: 3.50,
        priceExcVat: 3.04,
        vatRate: 0.15,
        vatAmount: 0.46
      },
      itemQuantity: {
        type: "SINGLE",
        quantity: 1
      },
      itemSumTotal: 3.50,
      itemMetaData: {
        reward: {
          valueType: "OBOS",
          valueCurrency: "Points",
          valueAmount: 20
        },
        co2: {
          co2Kg: 0.5
        }
      }
    },
  ],

  warranty: {
    duration: "1 year",
    expiryDate: "2024-09-21T12:35:00Z"
  },

  reward: {
    valueType: "OBOS",
    valueCurrency: "POINTS",
    valueAmountTotal: 40
  },
  
  orderSummary: {
    currencyIsoCode: "NOK",
    totalAmountIncVat: 29.48,
    totalAmountExcVat: 25.62,
    vatSummary: [
      {
        vatRate: 0.15,
        vatAmount: 3.86
      }
    ]
  },
  payment: [
    {
      type: "CARD",
      cardType: "VISA",
      maskedPan: "411111******1111",
      acquirerTransactionTimestamp: "2024-09-21T12:35:00Z",
      acquirerTerminalId: "45789632",
      acquirerMerchantId: "457896",
      transactionIdentifiers: {
        authorizationCode: "006589",
        systemTraceAuditNumber: "002569",
        retrievalReferenceNumber: "03112400369",
        transactionReference: "9584658754887"
      },
      transactionAmount: {
        merchantTransactionAmount: 29.48,
        merchantTransactionCurrency: "NOK",
        cardholderTransactionAmount: 29.48,
        cardholderTransactionCurrency: "NOK"
      },
      terminalProvider: "VERIFONE",
      transactionRawText: "Card: VISA\n **** **** **** 1111\nTr.Nr/Auth: 05555/006589\nPayee/business: 0029432413000/0001\n\nReference: 220901015555\n\nCharge 29.48 NOK\nContactless\n"
    }
  ],
  // qrCode: {
  //   value: "https://www.mintagesushi.com/receipt/123456789",
  //   valueEncoding: "UTF-8",
  //   errorCorrectionLevel: ErrorCorrectionLevels.L,
  // },
  footerText: "We hope to see you again soon!"
};

export const dummyTransactions: Transaction[] = [
    {
      id: "t1",
      date: "2024-09-21",
      merchant: {
        name: "Mintage Sushi",
        location: "Oslo, Norway"
      },
      amount: 29.48,
      description: "Dinner at Mintage Sushi",
      currency: "NOK",
      receipt: dummyReceipt
    }
  ];