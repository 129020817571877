// src/components/EANBarcode.tsx

import React, { useEffect, useRef, useState } from 'react';
import JsBarcode from 'jsbarcode';

interface EANBarcodeProps {
  value: string;
  width?: number;
  height?: number;
  fontSize?: number;
  lineColor?: string;
  background?: string;
  margin?: number;
  textMargin?: number;
  textAlign?: 'left' | 'center' | 'right';
  displayValue?: boolean;
  font?: string;
  format?: string;
}

const EANBarcode: React.FC<EANBarcodeProps> = ({
                                                 value,
                                                 width = 2,
                                                 height = 100,
                                                 fontSize = 20,
                                                 lineColor = '#000000',
                                                 background = '#ffffff',
                                                 margin = 10,
                                                 textMargin = 2,
                                                 textAlign = 'center',
                                                 displayValue = true,
                                                 font = 'silka',
                                                 format = 'EAN13'
                                               }) => {
  const barcodeRef = useRef<SVGSVGElement>(null);
  const [barcodeValue, setBarcodeValue] = useState<string>('0000000000000');

  useEffect(() => {
    // Validate and clean the input value
    const validateBarcode = (input: string): string => {
      // Remove any non-digit characters
      let cleanValue = input.replace(/\D/g, '');

      // If it's not 13 digits, pad with zeros or truncate
      if (cleanValue.length < 13) {
        cleanValue = cleanValue.padStart(13, '0');
      } else if (cleanValue.length > 13) {
        cleanValue = cleanValue.slice(0, 13);
      }

      return cleanValue;
    };

    // Set the validated barcode value
    const validBarcode = validateBarcode(value);
    setBarcodeValue(validBarcode);

    // Try to generate the barcode
    if (barcodeRef.current) {
      try {
        JsBarcode(barcodeRef.current, validBarcode, {
          format: format,
          width: width,
          height: height,
          displayValue: displayValue,
          fontSize: fontSize,
          lineColor: lineColor,
          background: background,
          margin: margin,
          textMargin: textMargin,
          textAlign: textAlign,
          font: font,
          valid: () => true, // Always consider the barcode valid
        });
      } catch (error) {
        console.warn('Barcode generation warning:', error);
        // If there's an error, fall back to a default barcode
        try {
          JsBarcode(barcodeRef.current, '0000000000000', {
            format: format,
            width: width,
            height: height,
            displayValue: displayValue,
            fontSize: fontSize,
            lineColor: lineColor,
            background: background,
            margin: margin,
            textMargin: textMargin,
            textAlign: textAlign,
            font: font,
            valid: () => true,
          });
        } catch (fallbackError) {
          console.error('Fallback barcode generation failed:', fallbackError);
        }
      }
    }
  }, [value, width, height, fontSize, lineColor, background, margin, textMargin, textAlign, displayValue, font, format]);

  return <svg ref={barcodeRef} />;
};

export default EANBarcode;