import React, { useState, useCallback } from 'react';
import TransactionItem from './TransactionItem';
import { dummyTransactions } from '../dummyData/transactions';
import '../styles/transactions.scss';
import {useDropdown} from "../App";

const TransactionOverview: React.FC = () => {
  const transactions = dummyTransactions;
  const { openDropdownId } = useDropdown();

  return (
      <div className="transaction-overview">
        {transactions.map((transaction) => (
            <TransactionItem
                key={transaction.id}
                transaction={transaction}
                isOpen={openDropdownId === transaction.id}
                onToggle={() => {}} // This is now handled in TransactionItem
                onOpen={(element) => {
                  element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                }}
            />
        ))}
      </div>
  );
};

export default TransactionOverview;