import React from 'react';
import { PaymentV1, XReceipts } from '../../model/receipt';
import PaymentCardLogo from '../../utils/PaymentCardLogo';

interface PaymentMethodProps {
    payment: PaymentV1;
    xReceipts: XReceipts;
}

const PaymentMethod: React.FC<PaymentMethodProps> = ({ payment, xReceipts }) => {
    return (
        <div className="inner-container payment-method-container">
            <h3 className="poppins-heading">PAYMENT METHOD</h3>

            <div className="payment-method-wrapper">
                <table className="payment-table">
                    <tbody>
                    <tr className="table-row">
                        <td className="left-content">
                            <div className="info-wrapper">
                                <span className="label-text">Transaction ID:</span>
                            </div>
                        </td>
                        <td className="right-content">
                            <span className="value-text">{xReceipts.roundTripId}</span>
                        </td>
                    </tr>
                    <tr className="table-row">
                        <td className="left-content">
                            <div className="info-wrapper">
                                <span className="label-text">Cardholder Reference:</span>
                            </div>
                        </td>
                        <td className="right-content">
                            <span className="value-text">{xReceipts.cardholderReference}</span>
                        </td>
                    </tr>
                    <tr className="table-row">
                        <td className="left-content">
                            <div className="info-wrapper">
                                <span className="label-text">Masked PAN:</span>
                            </div>
                        </td>
                        <td className="right-content">
                            <span className="value-text">{payment.maskedPan}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <div className="logo-wrapper">
                    <div className="card-logo">
                        <PaymentCardLogo cardType={payment.cardType} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentMethod;