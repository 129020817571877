// src/utils/dateFormatter.ts

interface TransactionTimestampFormatOptions {
    includeTime?: boolean;
    locale?: string;
    dateFormat?: 'numeric' | 'short' | 'long' | 'custom';
    timeFormat?: '12h' | '24h';
  }
  
  export const transactionTimestampFormat = (
    date: string | number | Date,
    options: TransactionTimestampFormatOptions = {}
  ): { formattedDate: string; formattedTime: string } => {
    const dateObj = new Date(date);
    const locale = options.locale || 'en-US';
  
    // Date formatter
    let dateFormatter: Intl.DateTimeFormat;
    if (options.dateFormat === 'custom') {
      dateFormatter = new Intl.DateTimeFormat(locale, {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      });
    } else {
      dateFormatter = new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: options.dateFormat || 'long',
        day: 'numeric'
      });
    }
  
    // Time formatter
    const timeFormatter = new Intl.DateTimeFormat(locale, {
      hour: '2-digit',
      minute: '2-digit',
      hour12: options.timeFormat !== '24h'
    });
  
    let formattedDate = dateFormatter.format(dateObj);
    let formattedTime = timeFormatter.format(dateObj);
  
    // Custom formatting for the specific format you requested
    if (options.dateFormat === 'custom') {
      const parts = dateFormatter.formatToParts(dateObj);
      const day = parts.find(part => part.type === 'day')?.value;
      const month = parts.find(part => part.type === 'month')?.value;
      const year = parts.find(part => part.type === 'year')?.value;
      formattedDate = `${day}.${month} ${year}`;
    }
  
    if (options.timeFormat === '24h') {
      const parts = timeFormatter.formatToParts(dateObj);
      const hour = parts.find(part => part.type === 'hour')?.value;
      const minute = parts.find(part => part.type === 'minute')?.value;
      formattedTime = `${hour}.${minute}`;
    }
  
    return { formattedDate, formattedTime };
  };