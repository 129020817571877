// src/components/LineItems.tsx

import React from 'react';
import LineItemMenu from './LineItemMenu';
import LineItemCard from './LineItemCard';
import { LineItemV1 } from '../../model/receipt';

interface LineItemsProps {
  lineItems: LineItemV1[];
  currencyIsoCode: string;
}

const LineItems: React.FC<LineItemsProps> = ({ lineItems, currencyIsoCode }) => {
    return (
        <div className="inner-container line-item-container">
            {/* Header */}
            <div className="line-items-table-header">
                <div className="frame-7">
                    <div className="poppins-heading">
                        <span className="header-text">LINE ITEM</span>
                    </div>
                </div>
                <div className="frame-8">
                    <div className="poppins-heading">
                        <span className="header-text">QTY</span>
                    </div>
                </div>
                <div className="frame-9">
                    <div className="poppins-heading">
                        <span className="header-text">PRICE</span>
                    </div>
                </div>
            </div>

            {/* Line Items */}
            {lineItems.map((item, index) => (
                <LineItemCard
                    key={`${item.itemIds.id}-${index}`}
                    item={item}
                    currencyIsoCode={currencyIsoCode}
                    isLastItem={index === lineItems.length - 1}
                />
            ))}
        </div>
    );
};

export default LineItems;