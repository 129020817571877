import React from 'react';
import stoLogo from '../../assets/sto-logo.png'; // Make sure the SVG is imported

export interface ProfileData {
    profileId: string;
    profileData: {
        name: string;
        age: number;
        profileImage: string;
        additionalDetails: {
            isAuthorized: boolean;
            membershipLevel: string;
            memberSince: string;
        }
    }
}

interface IDPageProps {
    cardholderReference: string;
    timeLeft: number | null;
    loading: boolean;
    error: string | null;
    profileData: ProfileData | null;
}

const IDPage: React.FC<IDPageProps> = ({
                                           cardholderReference,
                                           timeLeft,
                                           loading,
                                           error,
                                           profileData
                                       }) => {
    return (
        <div className="ID-Information-Container relative">
            <div className="header-container">
                <span className="poppins-heading">Authorization</span>
                {timeLeft !== null && (
                    <span className="Timer">{timeLeft}s</span>
                )}
            </div>

            {loading && (
                <div className="loading-indicator">
                    Loading profile...
                </div>
            )}

            {error && (
                <div className="error-message">
                    {error}
                </div>
            )}

            {profileData && profileData.profileData.additionalDetails.isAuthorized && timeLeft !== null && (
                <div className="profile-container">
                    <div className="profile-image">
                        <img src={profileData.profileData.profileImage} alt="Profile" />
                    </div>
                    <div className="Profile-Details">
                        <div className="Detail-Row">
                            <span className="Label">Name:</span>
                            <span className="Value">{profileData.profileData.name}</span>
                        </div>
                        <div className="Detail-Row">
                            <span className="Label">Age:</span>
                            <span className="Value">{profileData.profileData.age}</span>
                        </div>
                        <div className="Detail-Row">
                            <span className="Label">Member Since:</span>
                            <span className="Value">
                                {profileData.profileData.additionalDetails.memberSince}
                            </span>
                        </div>
                        <div className="Detail-Row">
                            <span className="Label">Level:</span>
                            <span className="Value">
                                {profileData.profileData.additionalDetails.membershipLevel}
                            </span>
                        </div>
                        <div className="Detail-Row mt-2 mb-2">
                            <span className="Label">STÖ ID:</span>
                            <span className="Value">
                                {cardholderReference}
                            </span>
                        </div>
                    </div>
                </div>
            )}
            <div className="logo-container">
                <div className="sto-logo">
                            <img
                                className="sto-logo"
                                src={stoLogo}
                                alt="STÖ Logo"
                            />
                        </div>
                    </div>
                </div>
                );
                };

                export default IDPage;