import React, { useRef, useEffect } from 'react';
import { ChevronRight } from 'lucide-react';
import { TfiReceipt } from "react-icons/tfi";
import TransactionDetail from './TransactionWrapper';
import { Transaction } from '../model/transaction';
import { isV0Receipt } from '../model/receipt';
import { transactionTimestampFormat } from '../utils/TransactionTimestampFormatter';
import { useDropdown } from "../App";
import '../styles/transactions.scss';

interface TransactionItemProps {
    transaction: Transaction;
    isOpen?: boolean;
    onToggle?: () => void;
    onOpen?: (element: HTMLDivElement) => void;
}

const TransactionItem = ({
                             transaction,
                             isOpen = false,
                             onToggle = () => {},
                             onOpen = () => {}
                         }: TransactionItemProps) => {
    const { setOpenDropdownId } = useDropdown();
    const { receipt } = transaction;
    const itemRef = useRef<HTMLDivElement>(null);

    const handleToggle = () => {
        if (isOpen) {
            setOpenDropdownId(null);
        } else {
            setOpenDropdownId(transaction.id);
        }
        onToggle();
    };

    const getTimestamp = (): string => {
        try {
            if (isV0Receipt(receipt)) {
                return receipt.generalInformation.date;
            }
            return receipt.generalInformation.systemTimestamp;
        } catch (error) {
            console.error('Error getting timestamp:', error);
            return new Date().toISOString();
        }
    };

    const getMerchantName = (): string => {
        try {
            if (isV0Receipt(receipt)) {
                return receipt.merchant.name || 'Unknown Merchant';
            }
            return receipt.merchant.merchantName || 'Unknown Merchant';
        } catch (error) {
            console.error('Error getting merchant name:', error);
            return 'Unknown Merchant';
        }
    };

    const { formattedDate, formattedTime } = transactionTimestampFormat(
        getTimestamp(),
        {
            dateFormat: 'custom',
            timeFormat: '24h',
            locale: 'no-NO'
        }
    );

    useEffect(() => {
        if (isOpen && itemRef.current) {
            onOpen(itemRef.current);
        }
    }, [isOpen, onOpen]);

    const formatAmount = (amount: number, currency: string): string => {
        try {
            return `${amount.toFixed(2)} ${currency}`;
        } catch (error) {
            console.error('Error formatting amount:', error);
            return `${amount} ${currency}`;
        }
    };

    return (
        <div
            ref={itemRef}
            className={`transaction-tab ${isOpen ? 'expanded' : ''}`}
            data-testid="transaction-item"
        >
            <div
                className="transaction-summary"
                onClick={handleToggle}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        handleToggle();
                    }
                }}
            >
                <div className="transaction-information-wrapper">
                    <div className="content-left">
                        <span className="merchant-name" title={getMerchantName()}>
                            {getMerchantName()}
                        </span>
                        <div className="date-time-wrapper">
                            <span className="transaction-date">{formattedDate}</span>
                            <span className="transaction-time">{formattedTime}</span>
                        </div>
                    </div>

                    <div className="content-right">
                        <span className="transaction-amount">
                            {formatAmount(transaction.amount, transaction.currency)}
                        </span>
                        <div className="transaction-navigation-wrapper">
                            <button
                                className="receipt-button"
                                aria-label="View Receipt"
                            >
                                <TfiReceipt className="icon" />
                            </button>
                            <button
                                className="open-button"
                                aria-label="Open Transaction Details"
                                onClick={handleToggle}
                            >
                                <ChevronRight className={`icon ${isOpen ? 'rotated' : ''}`} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen && <TransactionDetail transaction={transaction} />}
        </div>
    );
};

export default TransactionItem;