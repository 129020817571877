import React from 'react';
import { LuPhone, LuMail, LuHome } from 'react-icons/lu';
import googleMapsImage from '../../assets/google-maps.png';

interface MerchantInfoProps {
    merchant: {
        merchantName: string;
        merchantCompanyId?: string;
        branch: {
            branchName?: string;
            slogan?: string;
            email?: string;
            phone?: string;
            websiteUrl?: string;
            address: {
                addressLine1: string;
                addressLine2?: string;
                city: string;
                zipCode: string;
                country?: string;
            };
        };
    };
}

const MerchantInfo: React.FC<MerchantInfoProps> = ({ merchant }) => {
    const formatAddressForMaps = (address: any) => {
        const { addressLine1, city, zipCode, country } = address;
        return `${addressLine1}, ${city}, ${zipCode}, ${country}`.replace(/ /g, '+');
    };

    const formatAddress = (address: any) => {
        const { addressLine1, zipCode, city } = address;
        return `${addressLine1}, ${zipCode} ${city}`;
    };

    return (
        <div className="inner-container merchant-information-container">
            <div className="left-container merchant-information-left">
                <div className="merchant-information-header-wrapper">
                    <div className="heading">
                        <h2 className="poppins-heading merchant-title">MERCHANT INFORMATION</h2>
                    </div>
                    {merchant.merchantCompanyId && (
                        <div className="item-id-container">
                            <span className="poppins-small">VAT: {merchant.merchantCompanyId}</span>
                        </div>
                    )}
                </div>

                <div className="merchant-information-buttons-wrapper">
                    {merchant.branch.phone && (
                        <a
                            href={`tel:${merchant.branch.phone}`}
                            className="merchant-button"
                        >
                            <LuPhone className="merchant-button-icon" />
                            <span>Call</span>
                        </a>
                    )}

                    {merchant.branch.email && (
                        <a
                            href={`mailto:${merchant.branch.email}`}
                            className="merchant-button"
                        >
                            <LuMail className="merchant-button-icon" />
                            <span>Email</span>
                        </a>
                    )}

                    {merchant.branch.address && (
                        <a
                            href={`https://www.google.com/maps/search/?api=1&query=${formatAddressForMaps(merchant.branch.address)}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="merchant-button"
                        >
                            <LuHome className="merchant-button-icon" />
                            <span>Home</span>
                        </a>
                    )}
                </div>
            </div>

            <div className="right-container merchant-map-wrapper">
                <img
                    src={googleMapsImage}
                    alt="Google Maps Location"
                    className="merchant-map-image"
                    onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${formatAddressForMaps(merchant.branch.address)}`, '_blank')}
                />
            </div>
        </div>
    );
};

export default MerchantInfo;