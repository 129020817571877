// src/components/Header.tsx
import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/X-logo.png';
import '../styles/layout.scss';

const Header: React.FC = () => {
    return (
        <header className="header">
            <div className="inner-container">
                <div className="logo">
                    <img src={logo} alt="Company Logo"/>
                </div>
                <nav className="nav-menu">
                    <ul>
                        <li>
                            <NavLink
                                to="/Receipts"
                                className={({isActive}) => isActive ? 'active' : ''}
                            >
                                Receipts
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/Id"
                                className={({isActive}) => isActive ? 'active' : ''}
                            >
                                ID
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;