import React from 'react';
import { LuAreaChart } from "react-icons/lu"; // Assuming you want to use an icon

interface CardholderMembership {
    memberValue: string;
    memberValueType: string;
    memberValueProvider: string;
}

interface RewardProgramProps {
    cardholderMemberships: CardholderMembership[];
    reward: Reward;
}

interface Reward {
    valueType: string;
    valueCurrency: string;
    valueAmountTotal: number;
}

const RewardProgram: React.FC<RewardProgramProps> = ({ cardholderMemberships, reward }) => {
    if (!cardholderMemberships || cardholderMemberships.length === 0) {
        return null;
    }

    return (
        <div className="inner-container Information-Container">
            {/* Header Container */}
            <div className="Header-Container">
                <div className="RewardIcon">
                    <LuAreaChart />
                </div>
                <span className="reward-title">MY REWARD</span>
            </div>

            {/* Left Container */}
            <div className="Left-Container">
                <div className="Item-Info-Wrapper">
                    {/* Program Row */}
                    <div className="Program-Row-Wrapper">
                        <div className="Left-Wrapper">
                            <span>Program:</span>
                        </div>
                        <div className="Right-Wrapper">
                            <span>{cardholderMemberships[0].memberValueProvider}</span>
                        </div>
                    </div>

                    {/* Member Number Row */}
                    <div className="Member-Number-Wrapper">
                        <div className="Left-Wrapper">
                            <span>Member Number:</span>
                        </div>
                        <div className="Right-Wrapper">
                            <span>{cardholderMemberships[0].memberValue}</span>
                        </div>
                    </div>

                    {/* Points Row */}
                    <div className="Points-Wrapper">
                        <div className="Separator" />
                        <div className="Intermediate-Wrapper">
                            <div className="Left-Wrapper">
                                <span>Earned points</span>
                            </div>
                            <div className="Right-Wrapper">
                                <span>{reward.valueAmountTotal.toFixed(2)}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RewardProgram;