// src/config/api.config.ts
export const API_CONFIG = {
    BASE_URL: process.env.NODE_ENV === 'production'
        ? 'https://x.receipts.no' // Empty string for production since we're using relative paths
        : 'http://localhost:3001',
    ENDPOINTS: {
        RECEIPTS: '/receipts',
        ID_EVENTS: '/id',
        EVENTS: '/events',
        HEALTH: '/health',
        PROFILES: '/profiles'
    }
} as const;

// Log environment and configuration
console.log(`Environment: ${process.env.NODE_ENV}`);
console.log(`API Base URL: ${API_CONFIG.BASE_URL}`);
console.log("API Configuration loaded");

// Helper function to get full URL
export const getEndpointUrl = (endpoint: keyof typeof API_CONFIG.ENDPOINTS) => {
    const url = `${API_CONFIG.BASE_URL}${API_CONFIG.ENDPOINTS[endpoint]}`;
    console.log(`Generated URL for ${endpoint}:`, url);
    return url;
};

// Helper for profile URLs
export const getProfileUrl = (reference: string) => {
    return `${API_CONFIG.BASE_URL}${API_CONFIG.ENDPOINTS.PROFILES}/${reference}.json`;
};