import React, { useState, useEffect } from 'react';
import IDPage, {ProfileData} from "../components/id/IdPage";
import {getEndpointUrl, getProfileUrl} from '../config/api.config';

const ID: React.FC = () => {
    const [userReference, setUserReference] = useState<string>('Waiting for new transaction...');
    const [timeLeft, setTimeLeft] = useState<number | null>(null);
    const [profileData, setProfileData] = useState<ProfileData | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // Manual layout control
    const showLayout = false;
    const mockUserReference = 'ab680451-4a0b-4afd-be74-cb41ae5162fa';
    const mockTimeLeft = 30;

    const DEFAULT_MESSAGE = 'Waiting for new transaction...';

    // Function to load profile data
    const loadProfileData = async (reference: string) => {
        if (reference === DEFAULT_MESSAGE) {
            setProfileData(null);
            setError(null);
            setLoading(false);
            return;
        }

        try {
            setLoading(true);
            setError(null);

            const profileUrl = getProfileUrl(reference);
            console.log('Loading profile from:', profileUrl);

            const response = await fetch(profileUrl);

            // Check content type
            const contentType = response.headers.get('content-type');
            if (!response.ok || !contentType?.includes('application/json')) {
                console.error('Profile load failed:', {
                    status: response.status,
                    contentType,
                    text: await response.text() // Log the actual response for debugging
                });
                throw new Error('Profile not found or invalid response');
            }

            const data = await response.json();
            console.log('Profile loaded:', data);
            setProfileData(data);
        } catch (err) {
            console.error('Error loading profile:', err);
            setError(err instanceof Error ? err.message : 'Unable to load profile data');
            setProfileData(null);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!showLayout) {
            const eventSource = new EventSource(getEndpointUrl('ID_EVENTS'), {
                withCredentials: false
            });

            let timeoutId: ReturnType<typeof setTimeout> | null = null;
            let intervalId: ReturnType<typeof setInterval> | null = null;

            const startTimer = () => {
                setTimeLeft(30);

                // Clear any existing timers
                if (intervalId) clearInterval(intervalId);
                if (timeoutId) clearTimeout(timeoutId);

                // Start countdown
                intervalId = setInterval(() => {
                    setTimeLeft(prev => prev !== null ? prev - 1 : null);
                }, 1000);

                // Reset after 30 seconds
                timeoutId = setTimeout(() => {
                    setUserReference(DEFAULT_MESSAGE);
                    setTimeLeft(null);
                    setProfileData(null);
                    if (intervalId) clearInterval(intervalId);
                }, 30000);
            };

            eventSource.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);

                    // Skip connection messages
                    if (data.connected !== undefined) {
                        return;
                    }

                    // Handle cardholderReference update
                    if (data.cardholderReference) {
                        setUserReference(data.cardholderReference);
                        loadProfileData(data.cardholderReference);
                        startTimer();
                    }

                } catch (error) {
                    console.error('Error processing event:', error);
                }
            };

            eventSource.onerror = (error) => {
                console.error('SSE Connection Error:', error);
                // Attempt to reconnect after 5 seconds
                setTimeout(() => {
                    eventSource.close();
                }, 5000);
            };

            // Cleanup function
            return () => {
                eventSource.close();
                if (intervalId) clearInterval(intervalId);
                if (timeoutId) clearTimeout(timeoutId);
            };
        } else {
            // Handle mock data
            loadProfileData(mockUserReference);
        }
    }, [showLayout]);

    return (
        <div className="content-container">
            <IDPage
                cardholderReference={showLayout ? mockUserReference : userReference}
                timeLeft={showLayout ? mockTimeLeft : timeLeft}
                loading={loading}
                error={error}
                profileData={profileData}
            />
        </div>
    );
};

export default ID;