// models/v1/Receipt.ts
import {ErrorCorrectionLevels} from "../enums/errorCorrectionLevels";

export interface ReceiptV1 {
  id: string;
  xReceipts: {
    roundTripId: string;
    cardholderReference: string;
    cardholderMemberships?: Array<{
      memberValue: string;
      memberValueType: string;
      memberValueProvider: string;
    }>;
  };
  header?: {
    headerText?: string;
    merchantLogoUrl?: string;
  };
  generalInformation: {
    receiptType: string;
    systemTimestamp: string;
    receiptNumber: string;
    receiptNumberToBarcode: {
      format: string;
      applicationId: string;
    };
    freetext?: string;
  };
  merchant: {
    merchantName: string;
    merchantCompanyId: string;
    branch: {
      branchName?: string;
      slogan?: string;
      email?: string;
      phone?: string;
      websiteUrl?: string;
      address: {
        addressLine1: string;
        addressLine2?: string;
        city: string;
        zipCode: string;
        country: string;
      };
    };
  };
  lineItems: Array<{
    itemName: string;
    itemDescription?: string;
    itemIds: {
      id: string;
      ean?: string;
      gtin?: string;
      epd?: string;
    };
    itemPrice: {
      priceIncVat: number;
      priceExcVat: number;
      vatRate: number;
      vatAmount: number;
    };
    itemDiscount?: {
      amount: number;
      percentage: number;
      description: string;
    }
    itemQuantity: {
      type: string;
      quantity: number;
    };
    itemSumTotal: number;
    itemMetaData?: {
      reward?: {
        valueType: string;
        valueCurrency: string;
        valueAmount: number;
      },
      co2?: {
        co2Kg: number;
      }
    }
  }>;
  orderSummary: {
    currencyIsoCode: string;
    totalAmountIncVat: number;
    totalAmountExcVat: number;
    vatSummary: Array<{
      vatRate: number;
      vatAmount: number;
    }>;
  };
  warranty?: {
    duration: string;
    expiryDate: string;
  },
  reward?: {
    valueType: "OBOS",
    valueCurrency: "POINTS",
    valueAmountTotal: 40
  },
  payment: Array<{
    type: string;
    cardType: string;
    maskedPan: string;
    acquirerTransactionTimestamp: string;
    acquirerTerminalId: string;
    acquirerMerchantId: string;
    transactionIdentifiers: {
      authorizationCode: string;
      systemTraceAuditNumber: string;
      retrievalReferenceNumber: string;
      transactionReference: string;
    };
    transactionAmount: {
      merchantTransactionAmount: number;
      merchantTransactionCurrency: string;
      cardholderTransactionAmount?: number;
      cardholderTransactionCurrency?: string;
    };
    terminalProvider?: string;
    transactionRawText?: string;

  }>;
  qrCode?: {
    value: string;
    valueEncoding: string;
    errorCorrectionLevel: ErrorCorrectionLevels.L
  };
  footerText: "We hope to see you again soon!"
}

// models/v0/Receipt.ts
export interface ReceiptV0 {
  id: string;
  generalInformation: {
    type: string;
    cashierId: string;
    internalReceiptsNr: string;
    date: string;
    userReference: string;
    digitalReceipt: boolean;
  };
  merchant: {
    name: string;
    companyId: string;
    store: {
      name: string;
      email?: string;
      phone?: string;
      address: {
        streetAddress: string;
        city: string;
        zipCode: string;
        country: string;
      };
    };
  };
  lineItems: Array<{
    productName: string;
    productId: string;
    eanCode?: string;
    price: number;
    discount?: number;
    discountDescription?: string;
    quantity: number;
    vat: number;
    total: number;
  }>;
  vats: Array<{
    vatRate: number;
    totalVatAmount: number;
    totalAmountExcVat: number;
    totalAmountIncVat: number;
  }>;
  payments: Array<{
    paymentMethod: string;
    amount: number;
    paymentProvider: string;
    paymentInstrumentData: {
      cardData: {
        maskedPan: string;
        paymentBrand: string;
        terminalId: string;
        merchantlId: string;
        timeStamp: string;
        amount: number;
        ref: string;
      };
    };
  }>;
}

// models/v1/interfaces/index.ts

// Common interfaces shared between versions
export interface BaseAddress {
  city: string;
  zipCode: string;
  country: string;
}

// V1 specific interfaces
export interface CardholderMembership {
  memberValue: string;
  memberValueType: string;
  memberValueProvider: string;
}

export interface XReceipts {
  roundTripId: string;
  cardholderReference: string;
  stoId?: string;
  cardholderMemberships?: CardholderMembership[];
}

export interface Header {
  headerText?: string;
  merchantLogoUrl?: string;
}

export interface BarcodeInfo {
  format: string;
  applicationId: string;
}

export interface GeneralInformationV1 {
  receiptType: string;
  systemTimestamp: string;
  receiptNumber: string;
  receiptNumberToBarcode?: BarcodeInfo;
  freetext?: string;
}

export interface AddressV1 extends BaseAddress {
  addressLine1: string;
  addressLine2?: string;
}

export interface Warranty {
    duration: string;
    expiryDate: string;
}

export interface Branch {
  branchName?: string;
  slogan?: string;
  email?: string;
  phone?: string;
  websiteUrl?: string;
  address: AddressV1;
}

export interface MerchantV1 {
  merchantName: string;
  merchantCompanyId: string;
  branch: Branch;
}

export interface ItemIds {
  id: string;
  ean?: string;
  gtin?: string;
  epd?: string;
}

export interface ItemDiscount {
  amount: number;
  percentage: number;
  description: string;
}

export interface ItemPrice {
  priceIncVat: number;
  priceExcVat: number;
  vatRate: number;
  vatAmount: number;
}

export interface ItemQuantity {
  type: string;
  quantity: number;
}

export interface LineItemV1 {
  itemName: string;
  itemDescription?: string;
  itemIds: ItemIds;
  itemPrice: ItemPrice;
  itemDiscount?: ItemDiscount;
  itemQuantity: ItemQuantity;
  itemSumTotal: number;
  itemMetaData?: ItemMetaData;
}

export interface ItemMetaData {
  reward?: ItemReward;
  co2?: ItemCo2;
}

export interface ItemReward {
  valueType: string;
  valueCurrency: string;
  valueAmount: number;
}

export interface ItemCo2 {
  co2Kg: number;
}

export interface RewardData {
  valueType: string;
  valueCurrency: string;
  valueAmountTotal: number;
}

export interface VatSummary {
  vatRate: number;
  vatAmount: number;
}

export interface OrderSummary {
  currencyIsoCode: string;
  totalAmountIncVat: number;
  totalAmountExcVat: number;
  vatSummary: VatSummary[];
}

export interface TransactionAmount {
  merchantTransactionAmount: number;
  merchantTransactionCurrency: string;
  cardholderTransactionAmount?: number;
  cardholderTransactionCurrency?: string;
}

export interface PaymentV1 {
  type: string;
  cardType: string;
  maskedPan: string;
  transactionAmount: TransactionAmount;
  transactionIdentifiers: TransactionIdentifiers;
  acquirerTransactionTimestamp: string;
  acquirerTerminalId: string;
  acquirerMerchantId: string;
  terminalProvider?: string;
  transactionRawText?: string;
}

export interface TransactionIdentifiers {
    authorizationCode: string;
    systemTraceAuditNumber?: string;
    retrievalReferenceNumber?: string;
    transactionReference?: string;
}

// The main V1 Receipt interface
export interface ReceiptV1Int {
  id: string;
  xReceipts: XReceipts;
  header?: Header;
  generalInformationV1: GeneralInformationV1;
  merchant: MerchantV1;
  lineItemsV1: LineItemV1[];
  orderSummary: OrderSummary;
  paymentV1: PaymentV1[];
}

// models/v0/interfaces/index.ts

// V0 specific interfaces
export interface GeneralInformationV0 {
  type: string;
  cashierId: string;
  internalReceiptsNr: string;
  date: string;
  userReference: string;
  digitalReceipt: boolean;
}

export interface AddressV0 extends BaseAddress {
  streetAddress: string;
}

export interface Store {
  name: string;
  email?: string;
  phone?: string;
  address: AddressV0;
}

export interface MerchantV0 {
  name: string;
  companyId: string;
  store: Store;
}

export interface LineItemV0 {
  productName: string;
  productId: string;
  eanCode?: string;
  price: number;
  discount?: number;
  discountDescription?: string;
  quantity: number;
  vat: number;
  total: number;
}

export interface VatV0 {
  vatRate: number;
  totalVatAmount: number;
  totalAmountExcVat: number;
  totalAmountIncVat: number;
}

export interface CardData {
  maskedPan: string;
  paymentBrand: string;
  terminalId: string;
  merchantlId: string;
  timeStamp: string;
  amount: number;
  ref: string;
}

export interface PaymentInstrumentData {
  cardData: CardData;
}

export interface PaymentV0 {
  paymentMethod: string;
  amount: number;
  paymentProvider: string;
  paymentInstrumentData: PaymentInstrumentData;
}

// The main V0 Receipt interface
export interface ReceiptV0Int {
  id: string;
  generalInformation: GeneralInformationV0;
  merchant: MerchantV0;
  lineItems: LineItemV0[];
  vats: VatV0[];
  payments: PaymentV0[];
}

// models/shared/index.ts
export interface BaseReceipt {
  id: string;
}

export interface Reward {
  cardholderMemberships: CardholderMembership
  reward: string;
}

export interface Warranty {
  duration: string;
  expiryDate: string;
}

// Type guard functions
export const isV0Receipt = (receipt: any): receipt is ReceiptV0 => {
  return 'vats' in receipt && 'payments' in receipt;
};

export const isV1Receipt = (receipt: any): receipt is ReceiptV1 => {
  return 'xReceipts' in receipt && 'orderSummary' in receipt;
};

// Union type for any receipt version
export type Receipt = ReceiptV0 | ReceiptV1;