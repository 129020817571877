import React from 'react';
import EANBarcode from '../converters/EANBarcodeConverter';
import QRCodeComponent from '../converters/QRCodeConverter';
import { ReceiptV1 } from '../../model/receipt';
import dummyEanCode from '../../assets/EanCode.png';

interface VATReturnProps {
    receipt: ReceiptV1;
    vatInfo: {
        percentage: number;
        amount: number;
        amountExclVat: number;
        amountInclVat: number;
    };
}

const VATReturn: React.FC<VATReturnProps> = ({ receipt, vatInfo }) => {
    const isEANBarcode = receipt.generalInformation.receiptNumberToBarcode?.format === 'EAN';

    const isValidEANFormat = (value: string | undefined): boolean => {
        if (!value) return false;
        const cleanValue = value.replace(/\D/g, '');
        return /^\d{8}$|^\d{13}$/.test(cleanValue);
    };

    const renderBarcodeSection = () => {
        if (isEANBarcode && isValidEANFormat(receipt.generalInformation.receiptNumber)) {
            return (
                <EANBarcode
                    value={receipt.generalInformation.receiptNumber}
                    width={2}
                    height={50}
                    fontSize={16}
                    margin={20}
                    textMargin={5}
                    textAlign="center"
                    displayValue={true}
                    font="poppins"
                />
            );
        }

        if (receipt.qrCode?.value) {
            return (
                <QRCodeComponent
                    value={receipt.qrCode.value}
                    size={50}
                    level={receipt.qrCode.errorCorrectionLevel || 'L'}
                />
            );
        }

        // Display dummy EAN code image when no valid code is available
        return (
            <div className="dummy-code-container">
                <img
                    src={dummyEanCode}
                    alt="Sample EAN Code"
                    className="w-full max-h-[50px] object-contain"
                />
            </div>
        );
    };

    return (
        <div className="inner-container vat-return-container">
            {/* Return Information Section */}
            <div className="return-information-wrapper">
                <div className="return-barcode-qrcode-wrapper">
                    {renderBarcodeSection()}
                </div>
            </div>

            {/* VAT Information Section */}
            <div className="vat-information-container">
                <h6 className="vat-header">VAT</h6>
                <div className="item-info-wrapper">
                    <div className="vat-percentage-amount-wrapper">
                        <div className="left-wrapper">
                            <span>VAT {vatInfo.percentage}%</span>
                        </div>
                        <div className="right-wrapper">
                            <span>{vatInfo.amount.toFixed(2)} NOK</span>
                        </div>
                    </div>
                    <div className="amount-exclvat-wrapper">
                        <div className="left-wrapper">
                            <span>Amount excl. VAT</span>
                        </div>
                        <div className="right-wrapper">
                            <span>{vatInfo.amountExclVat.toFixed(2)} NOK</span>
                        </div>
                    </div>
                    <div className="vat-sumtotal-wrapper">
                        <div className="separator" />
                        <div className="amount-inclvat-wrapper">
                            <div className="left-wrapper">
                                <span>Amount incl. VAT</span>
                            </div>
                            <div className="right-wrapper">
                                <span>{vatInfo.amountInclVat.toFixed(2)} NOK</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VATReturn;