// src/components/PaymentCardLogo.tsx

import React from 'react';
import visaLogo from '../assets/visa-logo.png';
import mastercardLogo from '../assets/mastercard-logo.png';
import bankaxeptLogo from '../assets/bankaxept-logo.png';

interface PaymentCardLogoProps {
  cardType: string;
}

const PaymentCardLogo: React.FC<PaymentCardLogoProps> = ({ cardType }) => {
  const getCardLogo = (type: string) => {
    const lowercaseType = type.toLowerCase();
    if (lowercaseType.includes('visa')) {
      return visaLogo;
    } else if (lowercaseType.includes('mastercard')) {
      return mastercardLogo;
    } else if (lowercaseType.includes('bankaxept')) {
      return bankaxeptLogo;
    }
    return null;
  };

  const logo = getCardLogo(cardType);

  if (!logo) {
    return null;
  }

  return <img src={logo} alt={`${cardType} logo`} className="payment-card-logo" />;
};

export default PaymentCardLogo;