import React, { useCallback, useEffect, useState } from 'react';
import TransactionItem from './TransactionItem';
import { Receipt } from '../model/receipt';
import { Transaction } from '../model/transaction';
import { getEndpointUrl } from '../config/api.config';
import { mapReceiptToTransactionSafe } from '../utils/mappers';
import '../styles/transactions.scss';

interface StoredReceipt {
    id: number;
    data: Receipt;
    timestamp: string;
}

const STORAGE_KEY = 'stored_receipts';
const MAX_RECEIPTS = 20;

const TransactionOverview = () => {
    const [receipts, setReceipts] = useState<StoredReceipt[]>(() => {
        try {
            const stored = localStorage.getItem(STORAGE_KEY);
            return stored ? JSON.parse(stored) : [];
        } catch (error) {
            console.error('Error loading from localStorage:', error);
            return [];
        }
    });

    const [openReceiptId, setOpenReceiptId] = useState<number | null>(null);

    // Map stored receipt to transaction using the centralized mapper
    const mapStoredReceiptToTransaction = (storedReceipt: StoredReceipt): Transaction => {
        try {
            // Use the existing mapper and override the id and date
            const mappedTransaction = mapReceiptToTransactionSafe(storedReceipt.data);
            return {
                ...mappedTransaction,
                id: storedReceipt.id.toString(),
                date: storedReceipt.timestamp
            };
        } catch (error) {
            console.error('Error mapping stored receipt to transaction:', error);
            return {
                id: storedReceipt.id.toString(),
                date: storedReceipt.timestamp,
                amount: 0,
                description: 'Error Processing Transaction',
                currency: 'NOK',
                merchant: {
                    name: 'Unknown Merchant',
                    location: 'Unknown Location'
                },
                receipt: storedReceipt.data
            };
        }
    };

    useEffect(() => {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(receipts));
    }, [receipts]);

    useEffect(() => {
        const eventSource = new EventSource(getEndpointUrl('RECEIPTS'), {
            withCredentials: false
        });

        eventSource.onmessage = (event) => {
            try {
                const receiptData = JSON.parse(event.data);

                // Skip connection messages
                if (receiptData.connected !== undefined) {
                    console.log('Received connection status:', receiptData.connected);
                    return;
                }

                const newReceipt: StoredReceipt = {
                    id: Date.now(),
                    data: receiptData,
                    timestamp: new Date().toISOString()
                };

                setReceipts(prevReceipts => {
                    return [newReceipt, ...prevReceipts]
                        .slice(0, MAX_RECEIPTS);
                });

            } catch (error) {
                console.error('Error processing receipt:', error);
            }
        };

        eventSource.onerror = (error) => {
            console.error('SSE Connection Error:', error);
        };

        return () => eventSource.close();
    }, []);

    const handleToggle = useCallback((receiptId: number) => {
        setOpenReceiptId(prevId => prevId === receiptId ? null : receiptId);
    }, []);

    const handleOpen = useCallback((element: HTMLDivElement) => {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, []);

    return (
        <div className="transaction-container">
            <div className="transactions-list">
                {receipts.map(receipt => {
                    try {
                        const transaction = mapStoredReceiptToTransaction(receipt);
                        return (
                            <TransactionItem
                                key={receipt.id}
                                transaction={transaction}
                                isOpen={openReceiptId === receipt.id}
                                onToggle={() => handleToggle(receipt.id)}
                                onOpen={handleOpen}
                            />
                        );
                    } catch (error) {
                        console.error('Error rendering transaction:', error, receipt);
                        return null;
                    }
                })}
            </div>
        </div>
    );
};

export default TransactionOverview;