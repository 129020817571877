// mappers/ReceiptMapper.ts
import {ReceiptV0, ReceiptV1} from "./receipt";

export class ReceiptMapper {
    public static v0ToV1(receipt: ReceiptV0): ReceiptV1 {
        return {
            id: receipt.id,
            xReceipts: {
                roundTripId: receipt.generalInformation.internalReceiptsNr,
                cardholderReference: receipt.generalInformation.userReference,
            },
            generalInformation: {
                receiptType: receipt.generalInformation.type,
                systemTimestamp: receipt.generalInformation.date,
                receiptNumber: receipt.generalInformation.internalReceiptsNr,
                receiptNumberToBarcode: {
                    format: 'EAN',
                    applicationId: receipt.generalInformation.cashierId
                }
            },
            merchant: {
                merchantName: receipt.merchant.store.name,
                merchantCompanyId: receipt.merchant.companyId,
                branch: {
                    branchName: receipt.merchant.store.name,
                    email: receipt.merchant.store.email,
                    phone: receipt.merchant.store.phone,
                    address: {
                        addressLine1: receipt.merchant.store.address.streetAddress,
                        city: receipt.merchant.store.address.city,
                        zipCode: receipt.merchant.store.address.zipCode,
                        country: receipt.merchant.store.address.country
                    }
                }
            },
            lineItems: receipt.lineItems.map(item => ({
                itemName: item.productName,
                itemIds: {
                    id: item.productId,
                    ean: item.eanCode
                },
                itemPrice: {
                    priceIncVat: item.price,
                    priceExcVat: item.price - item.vat,
                    vatRate: (item.vat / (item.price - item.vat)) * 100,
                    vatAmount: item.vat
                },
                itemQuantity: {
                    type: 'SINGLE',
                    quantity: item.quantity
                },
                itemSumTotal: item.total
            })),
            orderSummary: {
                currencyIsoCode: 'NOK', // Default to NOK for v0
                totalAmountIncVat: receipt.vats[0].totalAmountIncVat,
                totalAmountExcVat: receipt.vats[0].totalAmountExcVat,
                vatSummary: receipt.vats.map(vat => ({
                    vatRate: vat.vatRate,
                    vatAmount: vat.totalVatAmount
                }))
            },
            payment: receipt.payments.map(payment => ({
                type: payment.paymentMethod,
                cardType: payment.paymentInstrumentData.cardData.paymentBrand,
                maskedPan: payment.paymentInstrumentData.cardData.maskedPan,
                acquirerTransactionTimestamp: payment.paymentInstrumentData.cardData.timeStamp,
                acquirerTerminalId: payment.paymentInstrumentData.cardData.terminalId,
                acquirerMerchantId: payment.paymentInstrumentData.cardData.merchantlId,
                transactionIdentifiers: {
                    authorizationCode: payment.paymentInstrumentData.cardData.ref,
                    systemTraceAuditNumber: payment.paymentInstrumentData.cardData.ref,
                    retrievalReferenceNumber: payment.paymentInstrumentData.cardData.ref,
                    transactionReference: payment.paymentInstrumentData.cardData.ref
                },
                transactionAmount: {
                    merchantTransactionAmount: payment.amount,
                    merchantTransactionCurrency: 'NOK', // Default to NOK for v0
                    cardholderTransactionAmount: payment.amount,
                    cardholderTransactionCurrency: 'NOK'
                },
                terminalProvider: payment.paymentProvider,
                transactionRawText: undefined
            })),
            footerText: "We hope to see you again soon!",
        };
    }

    public static v1ToV0(receipt: ReceiptV1): ReceiptV0 {
        return {
            id: receipt.id,
            generalInformation: {
                type: receipt.generalInformation.receiptType,
                cashierId: receipt.generalInformation.receiptNumberToBarcode.applicationId,
                internalReceiptsNr: receipt.generalInformation.receiptNumber,
                date: receipt.generalInformation.systemTimestamp,
                userReference: receipt.xReceipts.cardholderReference,
                digitalReceipt: true
            },
            merchant: {
                name: receipt.merchant.merchantName,
                companyId: receipt.merchant.merchantCompanyId,
                store: {
                    name: receipt.merchant.branch.branchName || receipt.merchant.merchantName,
                    email: receipt.merchant.branch.email,
                    phone: receipt.merchant.branch.phone,
                    address: {
                        streetAddress: receipt.merchant.branch.address.addressLine1,
                        city: receipt.merchant.branch.address.city,
                        zipCode: receipt.merchant.branch.address.zipCode,
                        country: receipt.merchant.branch.address.country
                    }
                }
            },
            lineItems: receipt.lineItems.map(item => ({
                productName: item.itemName,
                productId: item.itemIds.id,
                eanCode: item.itemIds.ean,
                price: item.itemPrice.priceIncVat,
                quantity: item.itemQuantity.quantity,
                vat: item.itemPrice.vatAmount,
                total: item.itemSumTotal
            })),
            vats: [{
                vatRate: receipt.orderSummary.vatSummary[0].vatRate,
                totalVatAmount: receipt.orderSummary.vatSummary[0].vatAmount,
                totalAmountExcVat: receipt.orderSummary.totalAmountExcVat,
                totalAmountIncVat: receipt.orderSummary.totalAmountIncVat
            }],
            payments: receipt.payment.map(p => ({
                paymentMethod: p.type,
                amount: p.transactionAmount.merchantTransactionAmount,
                paymentProvider: 'UNKNOWN',
                paymentInstrumentData: {
                    cardData: {
                        maskedPan: p.maskedPan,
                        paymentBrand: p.cardType,
                        terminalId: '0',
                        merchantlId: '0',
                        timeStamp: receipt.generalInformation.systemTimestamp,
                        amount: p.transactionAmount.merchantTransactionAmount,
                        ref: receipt.id
                    }
                }
            }))
        };
    }
}