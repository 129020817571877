import React from 'react';
import { Receipt as ReceiptIcon } from 'lucide-react';
import { Receipt, ReceiptV1 } from '../../model/receipt';
import { ReceiptAccessor } from '../../utils/mappers';
import MerchantLogo from './MerchantLogo';
import { SiXstate } from "react-icons/si";

interface ReceiptHeaderProps {
    receipt: Receipt;
}

const ReceiptHeader = ({ receipt }: ReceiptHeaderProps) => {
    const accessor = new ReceiptAccessor(receipt);
    const v1Receipt = accessor['receipt'] as ReceiptV1;

    const getHeaderInfo = () => {
        return {
            headerText: v1Receipt.header?.headerText || '',
            slogan: v1Receipt.merchant?.branch?.slogan || '',
            merchantLogoUrl: v1Receipt.header?.merchantLogoUrl
        };
    };

    const { headerText, slogan, merchantLogoUrl } = getHeaderInfo();
    const merchantName = accessor.getMerchantName();

    return (
        <div className="container receipt-container">
            <div className="inner-container">
                <div className="wrapper receipt-header-wrapper">
                    <div className="left-container receipt-header-left">
                        <div className="merchant-logo-container">
                            {merchantLogoUrl ? (
                                <MerchantLogo
                                    logoUrl={merchantLogoUrl}
                                    altText={`${accessor.getMerchantName()} logo`}
                                />
                            ) : (
                                <div className="backup-icon">
                                    {!headerText && !slogan ? (
                                        <SiXstate size={40} className="text-gray-600" />
                                    ) : (
                                        <ReceiptIcon size={50} strokeWidth={3} />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="right-container receipt-header-right">
                        <div className="merchant-info-header">
                            {headerText ? (
                                <h1 className="header-text">{headerText}</h1>
                            ) : (
                                <h1 className="header-text">{merchantName}</h1>
                            )}
                            {slogan && (
                                <p className="merchant-slogan">{slogan}</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReceiptHeader;