import React from 'react';
import { format, differenceInDays, addDays, parseISO, isPast } from 'date-fns';
import { LuShieldCheck } from "react-icons/lu";

interface WarrantyProps {
  receiptTimestamp: string;
  duration?: string;
  expiryDate?: string;
}

const Warranty: React.FC<WarrantyProps> = ({ receiptTimestamp, duration, expiryDate }) => {
  const calculateExpiryDate = (): Date => {
    if (expiryDate) {
      return parseISO(expiryDate);
    }

    if (duration) {
      const receiptDate = parseISO(receiptTimestamp);
      const [value, unit] = duration.split(' ');
      const durationValue = parseInt(value, 10);

      switch (unit.toLowerCase()) {
        case 'day':
        case 'days':
          return addDays(receiptDate, durationValue);
        case 'month':
        case 'months':
          return addDays(receiptDate, durationValue * 30);
        case 'year':
        case 'years':
          return addDays(receiptDate, durationValue * 365);
        default:
          return receiptDate;
      }
    }

    return parseISO(receiptTimestamp);
  };

  const calculatedExpiryDate = calculateExpiryDate();
  const formattedExpiryDate = format(calculatedExpiryDate, 'MMMM d, yyyy');
  const today = new Date();
  const isExpired = isPast(calculatedExpiryDate);
  const daysUntilExpiry = isExpired ? 0 : differenceInDays(calculatedExpiryDate, today);
  const expiryStatus = isExpired ? 'Expired' : `${daysUntilExpiry} days`;

  return (
      <div className="Reward-Information-Container">
        {/* Header Container */}
        <div className="Header-Container">
          <div className="WarrantyIcon">
            <LuShieldCheck />
          </div>
          <span>WARRANTY</span>
        </div>

        {/* Left Container */}
        <div className="Left-Container">
          <div className="Item-Info-Wrapper">
            {/* Duration Row */}
            <div className="Duration-Wrapper">
              <div className="Left-Wrapper">
                <span>Duration</span>
              </div>
              <div className="Right-Wrapper">
                <span>{duration}</span>
              </div>
            </div>

            {/* Expiry Date Row */}
            <div className="Expires-On-Wrapper">
              <div className="Left-Wrapper">
                <span>Expires On</span>
              </div>
              <div className="Right-Wrapper">
                <span>{formattedExpiryDate}</span>
              </div>
            </div>

            {/* Status Row */}
            <div className="Warranty-Status-Wrapper">
              <div className="Separator" />
              <div className="Status-Wrapper">
                <div className="Left-Wrapper">
                  <span>Status</span>
                </div>
                <div className="Right-Wrapper">
                  <span>{expiryStatus}</span>
                  <div className="Ellipse" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Warranty;