import React, { useState, createContext, useContext } from 'react';
import { Routes, Route, Navigate, BrowserRouter} from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';
import Footer from './components/Footer';
import './styles/global.scss';
import Receipts from "./pages/Receipts";
import ID from "./pages/Id";
import Header from "./components/Header";

// Create a context for managing open dropdowns
interface DropdownContextType {
  openDropdownId: string | null;
  setOpenDropdownId: (id: string | null) => void;
}

export const DropdownContext = createContext<DropdownContextType>({
  openDropdownId: null,
  setOpenDropdownId: () => {}
});

// Custom hook for easy context usage
export const useDropdown = () => {
  const context = useContext(DropdownContext);
  if (!context) {
    throw new Error('useDropdown must be used within a DropdownProvider');
  }
  return context;
};

const App = () => {
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);

  return (
      <PrimeReactProvider>
        <BrowserRouter>
        <DropdownContext.Provider value={{ openDropdownId, setOpenDropdownId }}>
          <div className="App">
            <Header />
            <Routes>
              <Route path="/receipts" element={<Receipts />} />
              <Route path="/id" element={<ID />} />
              <Route path="/" element={<Navigate to="/receipts" replace />} />
            </Routes>
            <Footer />
          </div>
        </DropdownContext.Provider>
        </BrowserRouter>
      </PrimeReactProvider>
  );
};

export default App;