// src/components/MerchantLogo.tsx

import React, { useState, useEffect } from 'react';
import { FaStore } from 'react-icons/fa'; // Make sure to install react-icons

interface MerchantLogoProps {
  logoUrl: string;
  altText: string;
}

const MerchantLogo: React.FC<MerchantLogoProps> = ({ logoUrl, altText }) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const img = new Image();
    img.src = logoUrl;
    img.onload = () => setImageSrc(logoUrl);
    img.onerror = () => {
      console.warn(`Failed to load image from ${logoUrl}, using fallback`);
      setError(true);
    };
  }, [logoUrl]);

  if (error) {
    return (
      <div className="merchant-logo">
        <FaStore size={200} />
      </div>
    );
  }

  return imageSrc ? (
    <img 
      src={imageSrc} 
      alt={altText} 
      className="merchant-logo"
      style={{ width: '100px', height: '100px', objectFit: 'contain' }}
    />
  ) : (
    <div className="merchant-logo-loading">Loading...</div>
  );
};

export default MerchantLogo;