import React from 'react';
import { ReceiptV1 } from '../../model/receipt';
import { Transaction } from '../../model/transaction';
import { LuSend, LuDownload } from "react-icons/lu";

interface ReceiptActionsProps {
    receipt: ReceiptV1;
    transaction?: Transaction;
}

const ReceiptActions: React.FC<ReceiptActionsProps> = ({
                                                           transaction,
                                                       }) => {
    const handleForward = () => {
        // Implementation for forwarding receipt as PDF
        console.log('Forwarding receipt as PDF...');
    };

    const handleDownloadPDF = () => {
        // Implementation for downloading receipt as PDF
        console.log('Downloading receipt as PDF...');
    };

    return (
        <div className="receipt-navigation-container">

            <button
                className="share-receipt-button"
                onClick={handleForward}
            >
                <LuSend className="action-icon" />
                <span>Share Receipt</span>
            </button>

            <button
                className="download-receipt-button"
                onClick={handleDownloadPDF}
            >
                <LuDownload className="action-icon" />
                <span>Download</span>
            </button>

        </div>
    );
};

export default ReceiptActions;