// src/pages/Receipts.tsx
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import TransactionOverview from '../components/TransactionOverview';
import TransactionOverviewApi from "../components/TransactionOverviewApi";

const queryClient = new QueryClient();

const Receipts: React.FC = () => {
  return (
      <QueryClientProvider client={queryClient}>
          <main className="content-container">
              <h5 className="text-left mb-2 mt-5">My Transactions</h5>
              <TransactionOverviewApi/>
              <h6 className="transaction-split mt-5 mb-5"> Previous Transactions </h6>
              <TransactionOverview/>
          </main>
      </QueryClientProvider>
  );
};

export default Receipts;