import '../styles/transactions.scss';
import React from 'react';
import { Transaction } from '../model/transaction';
import { ReceiptV1 } from '../model/receipt';
import { ReceiptAccessor } from '../utils/mappers';
import LineItems from './receipt/LineItems';
import MerchantInfo from './receipt/MerchantInfo';
import PaymentMethod from './receipt/PaymentMethod';
import ReceiptHeader from './receipt/ReceiptHeader';
import Warranty from './receipt/Warranty';
import RewardProgram from './receipt/RewardProgram';
import ReceiptActions from "./actions/ReceiptActions";
import VATReturn from "./receipt/VatReturn";

interface TransactionProps {
    transaction: Transaction;
}

const TransactionWrapper = ({ transaction }: TransactionProps) => {
    const { receipt } = transaction;
    const accessor = new ReceiptAccessor(receipt);
    const v1Receipt = accessor['receipt'] as ReceiptV1; // Access the converted V1 receipt

    const renderSection = (content: React.ReactNode) => content ? <>{content}</> : null;

    // Get Co2 info from line items
    const getCo2Info = () => {
        const lineItems = v1Receipt.lineItems || [];
        const hasCo2 = lineItems.some(item => item.itemMetaData?.co2?.co2Kg !== undefined);
        if (!hasCo2) return null;

        const totalCo2 = lineItems.reduce((sum: number, item) => {
            return sum + (item.itemMetaData?.co2?.co2Kg || 0);
        }, 0);

        return { co2Kg: totalCo2 };
    };

    return (
        <div className="transaction-detail">
            {renderSection(
                <ReceiptHeader receipt={v1Receipt} />
            )}

            <div className="receipt-navigation-container">
                {renderSection(
                    <ReceiptActions receipt={v1Receipt} />
                )}
            </div>

            {renderSection(
                <MerchantInfo merchant={v1Receipt.merchant} />
            )}

            <div className="line-items">
                {renderSection(
                    <LineItems
                        lineItems={v1Receipt.lineItems || []}
                        currencyIsoCode={accessor.getCurrency()}
                    />
                )}
            </div>

            {renderSection(
                v1Receipt.payment?.[0] && (
                    <PaymentMethod
                        payment={v1Receipt.payment[0]}
                        xReceipts={v1Receipt.xReceipts}
                    />
                )
            )}

            {renderSection(
                v1Receipt.orderSummary && (
                        <VATReturn
                            receipt={v1Receipt}
                            vatInfo={{
                                // Safely access the first VAT rate from the array
                                percentage: v1Receipt.orderSummary.vatSummary?.[0]?.vatRate || 0,
                                amount: v1Receipt.orderSummary.vatSummary?.[0]?.vatAmount || 0,
                                amountExclVat: v1Receipt.orderSummary.totalAmountExcVat || 0,
                                amountInclVat: v1Receipt.orderSummary.totalAmountIncVat || 0
                            }}
                        />
                )
            )}

            {renderSection(
                v1Receipt.xReceipts?.cardholderMemberships?.length &&
                v1Receipt.reward && (
                    <RewardProgram
                        cardholderMemberships={v1Receipt.xReceipts.cardholderMemberships}
                        reward={v1Receipt.reward}
                    />
                )
            )}

            {renderSection(
                v1Receipt.warranty && (
                    <Warranty
                        duration={v1Receipt.warranty.duration}
                        expiryDate={v1Receipt.warranty.expiryDate}
                        receiptTimestamp={accessor.getTimestamp()}
                    />
                )
            )}
        </div>
    );
};

export default TransactionWrapper;